<template>
  <div class="box">
    <img class="errbg" src="../assets/404-bj@2x.png" />
    <div class="errimg">
      <img src="../assets/404-img@2x.png" />
      <div class="text" @click="$router.replace('/')">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {}
  },
  mounted() {
    sessionStorage.setItem("pages", "error")
  },
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}
.errbg {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.errimg {
  width: 555px;
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
  .text {
    position: absolute;
    font-size: 28px;
    right: 80px;
    bottom: 100px;
    color: #ffef68;
    cursor: pointer;
  }
}
</style>
